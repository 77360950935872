import { useQuery } from "@tanstack/react-query";

// Internal
import { API_ENTRYPOINT } from "@/utils/fetch";
import { httpRequests } from "./_shared";
import { IAttendance } from "@/types/attendance-sheet.types";

// QUERY KEYS
export const providerQueryKeys = {
  all: ["providers"] as const,
  details: () => [...providerQueryKeys.all, "detail"] as const,
  detail: (id: string) =>
    [...providerQueryKeys.details(), "detail", id] as const,
};

/*
 * Get provider info/details
 */
export const _getProviderDetails = async (id: string) => {
  return httpRequests.get<IAttendance[]>(`${API_ENTRYPOINT}/providers/${id}`);
};
// react-query hook
export const useGetProviderDetails = (id: string) => {
  return useQuery<IAttendance[], Error>({
    queryKey: providerQueryKeys.detail(id),
    queryFn: async () => {
      if (!id) return Promise.resolve([]);

      const data = await _getProviderDetails(id);

      return data;
    },
  });
};
