import React from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

// Antd
import { Alert, Button, Result } from "antd";

// Icons
import { MailOutlined } from "@ant-design/icons";

export default function GeneralErrorState({
  error,
  extra,
}: {
  error?: any;
  extra?: React.ReactNode;
}) {
  let errorMesage;
  if (error instanceof TypeError) {
    errorMesage = error.stack || error.toString();
  }
  if (!errorMesage) {
    errorMesage =
      error?.response?.data?.detail || error?.message || error?.code;
  }

  const statusCode = error?.response?.status || error?.response?.data?.status;

  const [searchParams] = useSearchParams();
  const dataQueryParam = searchParams.get("data");

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <Result
        status="error"
        title="Something went wrong"
        subTitle="Please try again later, or contact support."
        extra={
          <div className="flex justify-center gap-2">
            <Link
              key="home"
              to={`/?data=${dataQueryParam}`}
              onClick={
                location.pathname === "/"
                  ? () => {
                      navigate(0);
                    }
                  : undefined
              }
            >
              <Button>Home</Button>
            </Link>

            <a key="support" href="mailto:eas@usa.childcareaware.org">
              <Button icon={<MailOutlined />} type="primary">
                Contact Support
              </Button>
            </a>
          </div>
        }
      />

      <Alert
        message={<p className="font-semibold">Technical details:</p>}
        description={
          <p>
            {errorMesage}

            {statusCode !== undefined && (
              <p className={errorMesage ? "mt-4" : ""}>
                <span className="font-semibold">Status code:</span> {statusCode}
              </p>
            )}

            {extra && <div className="mt-6">{extra}</div>}
          </p>
        }
        type="error"
        className="w-[80%] max-w-[600px] m-auto"
      />
    </>
  );
}
