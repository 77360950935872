import { CSSProperties } from "react";
import { useSearchParams } from "react-router-dom";

// Internal
import { useUrlParams } from "@/hooks/useUrlParams";
import { DayStatus, IAttendance } from "@/types/attendance-sheet.types";
import {
  DetailDrawerLineItem,
  DetailDrawerSectionTitle,
} from "@/components/DetailsDrawer";
import { getDayStatus } from "@/utils/attendance";
import { parseDayMonthYearString } from "@/utils/dates";

// Antd
import { Calendar, Space, Tag } from "antd";
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  StopTwoTone,
} from "@ant-design/icons";

// MISC
import { startCase } from "lodash-es";
import dayjs from "dayjs";

export default function ReadOnlyView({
  attendanceSheet,
}: {
  attendanceSheet: IAttendance | null;
}) {
  // Search params
  const [searchParams] = useSearchParams();
  const { userType } = useUrlParams({
    searchParams,
  });

  // Search params
  const startDate = attendanceSheet?.periodStartDate;
  const endDate = attendanceSheet?.periodEndDate;

  // Attendance sheet status
  const _status = getDayStatus(attendanceSheet, {
    userType,
  });

  /*
   * Determine if care was received for the entire month
   */
  const _hasNoCareProvidedDays = attendanceSheet?.days?.some(
    (day) => day.code === DayStatus.NO_CARE_PROVIDED
  );

  return (
    <div>
      <div className="flex gap-4 lg:gap-32 flex-col lg:flex-row mb-12 lg:mb-0 mt-2">
        <List
          className="lg:w-1/4 lg:max-w-[270px]"
          items={[
            {
              title: "Overview",
              items: [
                {
                  label: "Period",
                  value: `${dayjs(attendanceSheet?.periodStartDate).format(
                    "MMM DD, YYYY"
                  )} - ${dayjs(attendanceSheet?.periodEndDate).format(
                    "MMM DD, YYYY"
                  )}`,
                },
                {
                  label: "Status",
                  value: _status ? (
                    <Tag className="m-0" color={_status.color}>
                      {_status.title}
                    </Tag>
                  ) : (
                    <Tag className="m-0">
                      {startCase(attendanceSheet?.status?.split("_").join(" "))}
                    </Tag>
                  ),
                },
              ],
            },
          ]}
        />

        {attendanceSheet?.submittedViaPaper !== true && (
          <>
            <List
              className="lg:w-1/4 lg:max-w-[270px]"
              items={[
                {
                  title: "Details",
                  items: [
                    {
                      label: "Received care for entire month",
                      value:
                        _hasNoCareProvidedDays ||
                        attendanceSheet?.chargedForMonth === false ? (
                          <X />
                        ) : (
                          <Checkmark />
                        ),
                    },
                    {
                      label: "Termination Date",
                      value: attendanceSheet?.terminationDate
                        ? dayjs(attendanceSheet?.terminationDate).format(
                            "MMM DD, YYYY"
                          )
                        : "—",
                    },
                    ...(attendanceSheet?.declineReason
                      ? [
                          {
                            label: "Reason family declined",
                            value: attendanceSheet?.declineReason,
                          },
                        ]
                      : []),
                    ...(attendanceSheet?.amountBilled
                      ? [
                          {
                            label: "Amount Billed",
                            value: `$${attendanceSheet?.amountBilled.toFixed(
                              2
                            )}`,
                          },
                        ]
                      : []),
                    {
                      label: "Billing statement uploaded",
                      value: attendanceSheet?.artifactId ? (
                        <Checkmark />
                      ) : (
                        <X />
                      ),
                    },
                  ],
                },
              ]}
            />

            {(attendanceSheet?.submittedByProvider ||
              attendanceSheet?.submittedByProviderDate) && (
              <List
                className="lg:w-1/4 lg:max-w-[270px]"
                items={[
                  {
                    title: "Approvals",
                    items: [
                      ...(attendanceSheet?.submittedByProvider ||
                      attendanceSheet?.submittedByProviderDate
                        ? [
                            {
                              label: "Provider",
                              value: (
                                <>
                                  <p>
                                    {attendanceSheet?.submittedByProvider ||
                                      "—"}
                                  </p>
                                  <Space>
                                    <p className="text-xs opacity-50">
                                      {attendanceSheet?.submittedByProviderDate
                                        ? dayjs(
                                            attendanceSheet?.submittedByProviderDate
                                          ).format("MMM DD, YYYY")
                                        : "—"}
                                    </p>

                                    <Checkmark />
                                  </Space>
                                </>
                              ),
                            },
                          ]
                        : []),
                      ...(attendanceSheet?.submittedByFamily ||
                      attendanceSheet?.submittedByFamilyDate
                        ? [
                            {
                              label: "Parent",
                              value: (
                                <>
                                  <p>
                                    {attendanceSheet?.submittedByFamily || "—"}
                                  </p>
                                  {attendanceSheet?.status ===
                                  "FAMILY_DENIED" ? (
                                    <Space>
                                      <p className="text-xs opacity-50">
                                        Rejected
                                      </p>

                                      <X />
                                    </Space>
                                  ) : (
                                    <Space>
                                      <p className="text-xs opacity-50">
                                        {attendanceSheet?.submittedByFamilyDate
                                          ? dayjs(
                                              attendanceSheet?.submittedByFamilyDate
                                            ).format("MMM DD, YYYY")
                                          : "—"}
                                      </p>

                                      <Checkmark />
                                    </Space>
                                  )}
                                </>
                              ),
                            },
                          ]
                        : []),
                    ],
                  },
                ]}
              />
            )}
          </>
        )}
      </div>

      <div className="mt-12 pb-8">
        <DetailDrawerSectionTitle
          title="Attendance"
          className="mt-6 mb-8 pb-2 border-b border-solid border-gray-200"
        />

        <Calendar
          className="calendar__read-only"
          fullCellRender={(date) => {
            const _Content = () => {
              const _day = attendanceSheet?.days?.find(
                (day) => day.dateAttended === date.format("YYYY-MM-DD")
              );

              if (
                !_day ||
                (attendanceSheet?.terminationDate &&
                  date.isAfter(dayjs(attendanceSheet?.terminationDate)))
              ) {
                return false;
              }

              if (
                attendanceSheet?.terminationDate &&
                date.isSame(dayjs(attendanceSheet?.terminationDate))
              ) {
                return (
                  <div className="flex">
                    <span className="w-[60%] font-semibold">Terminated</span>

                    <span className="flex-grow"></span>

                    <Terminated />
                  </div>
                );
              }

              if (_day?.code === DayStatus.NO_CARE_PROVIDED) {
                return (
                  <div className="flex">
                    <span className="w-[60%]">No Care Provided</span>

                    <span className="flex-grow"></span>

                    <X />
                  </div>
                );
              }

              return (
                <div className="flex">
                  <span>Attended</span>

                  <span className="flex-grow"></span>

                  <Checkmark />
                </div>
              );
            };

            return (
              <div className="ant-picker-cell-inner ant-picker-calendar-date">
                <div className="ant-picker-calendar-date-value">
                  {date.date()}
                </div>

                <div className="ant-picker-calendar-date-content">
                  <_Content />
                </div>
              </div>
            );
          }}
          disabledDate={(date) =>
            !attendanceSheet?.days?.find(
              (day) => day.dateAttended === date.format("YYYY-MM-DD")
            ) ||
            (attendanceSheet?.terminationDate &&
              date.isAfter(dayjs(attendanceSheet?.terminationDate)))
          }
          headerRender={() => null}
          validRange={[
            parseDayMonthYearString(startDate),
            parseDayMonthYearString(endDate),
          ]}
          defaultValue={parseDayMonthYearString(startDate)}
          style={{ maxWidth: "1200px" }}
        />
      </div>
    </div>
  );
}

const Checkmark = () => <CheckCircleTwoTone twoToneColor="#52c41a" />;
const X = () => <CloseCircleTwoTone twoToneColor="#ff4d4f" />;
const Terminated = () => <StopTwoTone twoToneColor="#ff4d4f" />;

function List({
  items,
  className,
  style,
}: {
  items: any[];
  className?: string;
  style?: CSSProperties;
}) {
  return items.map((item, index) => (
    <div key={index} className={className} style={style}>
      <DetailDrawerSectionTitle title={item.title} className="mb-4" />

      <div className="flex flex-col gap-2">
        {item.items?.map((_item: any, _index: any) => (
          <DetailDrawerLineItem
            key={_index}
            title={_item.label}
            value={_item.value}
            className="!items-start"
          />
        ))}
      </div>
    </div>
  ));
}
