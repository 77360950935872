import React, { Component, ReactNode } from "react";
import "source-map-support/register";

// Antd
import { Layout } from "antd";
import { Header } from "antd/es/layout/layout";

// Internal
import Container from "./components/Container";
import GeneralErrorState from "./components/GeneralErrorState";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

const isDevOrStaging = ["dev", "staging"].includes(
  import.meta.env.VITE_SITE_ENV
);

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error("ErrorBoundary caught an error", error, errorInfo);
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Layout className="flex flex-col min-h-screen">
          <div className="flex-grow">
            <Header
              className={`px-4 sm:px-12${isDevOrStaging ? " bg-[#fbad3b] border-b border-solid border-b-[#00142a4a]" : ""}`}
              style={{ height: "100px", display: "flex", alignItems: "center" }}
            >
              <div>
                <img
                  src="/images/ccaoa-logo.png"
                  alt="Logo"
                  className="table h-20 mr-6"
                />
              </div>

              <div className="flex-grow text-white text-right">
                {isDevOrStaging && (
                  <div className="h-4 leading-none text-[#000] opacity-70">
                    {import.meta.env.VITE_SITE_ENV === "dev"
                      ? "DEV"
                      : "STAGING"}{" "}
                    environment
                  </div>
                )}
              </div>
            </Header>

            <Container>
              <GeneralErrorState
                error={
                  this.state.error
                    ? { message: this.state.error.toString() }
                    : { message: "Unknown error" }
                }
                extra={this.state.errorInfo?.componentStack}
              />
            </Container>
          </div>
        </Layout>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
