export default function EasScheduleLegend() {
  return (
    <>
      <p className="mt-8 font-semibold">Legend</p>

      <div className="table">
        <div className="flex p-3 mt-2 bg-gray-200 gap-6 rounded-lg">
          <div className="flex gap-2 items-center">
            <div className="w-7 h-7 rounded-md bg-slate-800 text-white relative">
              <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-md font-semibold">
                AT
              </span>
            </div>

            <span className="font-md">Attended</span>
          </div>

          <div className="flex gap-2 items-center">
            <div className="w-7 h-7 rounded-md bg-blue-800 text-white relative">
              <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-md font-semibold">
                NC
              </span>
            </div>

            <span className="font-md">No Care Provided</span>
          </div>

          <div className="flex gap-2 items-center">
            <div className="w-7 h-7 rounded-md bg-red-500 text-white relative">
              <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-md font-semibold">
                TD
              </span>
            </div>

            <span className="font-md">Termination Date</span>
          </div>
        </div>
      </div>
    </>
  );
}
