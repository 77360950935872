import { useMemo } from "react";
import { Link, useSearchParams } from "react-router-dom";

// Antd
import {
  Badge,
  Button,
  Card,
  ConfigProvider,
  DatePicker,
  Dropdown,
  Empty,
  Input,
  Space,
  Table,
  Tag,
} from "antd";

// Internal
import { UserType, useUrlParams } from "@/hooks/useUrlParams";
import {
  AttendanceStatus,
  AttendanceStatusTitles,
  IAttendance,
} from "@/types/attendance-sheet.types";
import { getDayStatus } from "@/utils/attendance";
import { parseEndDateQueryParam } from "@/utils/dates";

// Icons
import { Filter, Search } from "@carbon/icons-react";

// MISC
import dayjs from "dayjs";
import { startCase } from "lodash-es";

export default function EasTable({
  data,
  loading,
}: {
  data: IAttendance[];
  loading: boolean;
}) {
  // Search params
  const [searchParams, setSearchParams] = useSearchParams();
  const endQueryParam = searchParams.get("end") || "";
  const searchQuery = searchParams.get("search") || "";

  const { isFamilyView, userType } = useUrlParams({
    searchParams,
  });

  // Status
  const statusQueryParam = searchParams
    .get("status")
    ?.split(",")
    .filter(Boolean);

  const selectedStatuses = statusQueryParam
    ? statusQueryParam
    : isFamilyView
      ? [AttendanceStatus.PROVIDER_SUBMITTED, AttendanceStatus.FAMILY_DENIED]
      : [AttendanceStatus.INCOMPLETE, AttendanceStatus.FAMILY_DENIED];

  const dataQuery = searchParams.get("data");

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_: unknown, attendanceSheet: IAttendance) => {
        const _url = `/attendance/${attendanceSheet?.id}?data=${dataQuery}&platform=${attendanceSheet.platform}&end=${endQueryParam}`;

        const _status = attendanceSheet?.status?.toLowerCase();
        const _isEditable = !(_status === "incomplete" && isFamilyView);
        if (!_isEditable) {
          return attendanceSheet?.child?.name;
        }

        return <Link to={_url}>{attendanceSheet?.child?.name}</Link>;
      },
      sorter: (a: IAttendance, b: IAttendance) =>
        (a?.child?.name || "").localeCompare(b?.child?.name || ""),
    },
    {
      title: "Child ID",
      key: "id",
      render: (_: unknown, datum: IAttendance) => {
        return datum?.child?.id;
      },
      sorter: (a: IAttendance, b: IAttendance) =>
        (a?.child?.id || 0) - (b?.child?.id || 0),
    },
    ...(userType === UserType.PROVIDER
      ? [
          {
            title: "Household ID",
            key: "id",
            render: (_: unknown, datum: IAttendance) => {
              return datum?.child?.householdId;
            },
            sorter: (a: IAttendance, b: IAttendance) =>
              (a?.child?.householdId || 0) - (b?.child?.householdId || 0),
          },
        ]
      : [
          {
            title: "Provider ID",
            key: "id",
            render: (_: unknown, datum: IAttendance) => {
              return datum?.provider?.id;
            },
            sorter: (a: IAttendance, b: IAttendance) =>
              (a?.provider?.id || 0) - (b?.provider?.id || 0),
          },
        ]),
    {
      title: "Period",
      key: "period",
      render: (_: unknown, datum: IAttendance) => {
        const _parsedStart = dayjs(datum?.periodStartDate);
        const _parsedEnd = dayjs(datum?.periodEndDate);

        if (
          _parsedStart.month() !== _parsedEnd.month() ||
          _parsedStart.year() !== _parsedEnd.year()
        ) {
          return `${dayjs(datum?.periodStartDate).format(
            "MMM DD, YYYY"
          )} - ${dayjs(datum?.periodEndDate).format("MMM DD, YYYY")}`;
        }

        return `${dayjs(datum?.periodStartDate).format("MMM DD")}-${dayjs(
          datum?.periodEndDate
        ).format("DD, YYYY")}`;
      },
      sorter: (a: IAttendance, b: IAttendance) =>
        +new Date(a?.periodStartDate || "") -
        +new Date(b?.periodStartDate || ""),
      defaultSortOrder: "ascend",
    },
    {
      title: "Status",
      key: "tags",
      render: (_: unknown, attendance: IAttendance) => {
        const _status = getDayStatus(attendance, {
          userType,
        });

        return _status ? (
          <Tag color={_status.color}>{_status.title}</Tag>
        ) : (
          <Tag>{startCase(attendance.status.split("_").join(" "))}</Tag>
        );
      },
      sorter: (a: IAttendance, b: IAttendance) => {
        const { title: aStatusTitle } = getDayStatus(a, {
          userType,
        });

        const { title: bStatusTitle } = getDayStatus(b, {
          userType,
        });

        return (aStatusTitle || "").localeCompare(bStatusTitle || "");
      },
    },
  ];

  const _numberOfRecords = Array.isArray(data) ? data.length : 0;

  const tableData = useMemo(() => {
    return (data || []).filter((datum) => {
      if (!selectedStatuses.includes(datum.status)) {
        return false;
      }

      const _childName = datum?.child?.name?.toLowerCase();
      const _childId = datum?.child?.id?.toString();

      if (userType === UserType.PROVIDER) {
        const _householdId = datum?.child?.householdId?.toString();
        return (
          _childName.includes(searchQuery.toLowerCase()) ||
          _childId.includes(searchQuery) ||
          _householdId.includes(searchQuery)
        );
      } else {
        const _providerId = datum?.provider?.id?.toString();
        return (
          _childName.includes(searchQuery.toLowerCase()) ||
          _childId.includes(searchQuery) ||
          _providerId.includes(searchQuery)
        );
      }
    });
  }, [data, searchQuery, statusQueryParam, userType]);

  const _hasRecordsOutsideOfFilteredState =
    tableData.length !== _numberOfRecords;

  return (
    <Card
      title={
        loading
          ? undefined
          : `${tableData.length}${
              _hasRecordsOutsideOfFilteredState ? ` of ${_numberOfRecords}` : ""
            } Attendance Sheet${_numberOfRecords !== 1 ? "s" : ""}`
      }
      extra={
        <Space className="flex-wrap">
          {userType === UserType.PROVIDER && (
            <DatePicker
              allowClear={false}
              picker="month"
              format="MMM YYYY"
              disabledDate={(current) => {
                if (current.isSame(dayjs(), "month")) {
                  // Disable the current month if it's before the 25th
                  return dayjs().date() < 25;
                }

                return current > dayjs().endOf("month");
              }}
              onChange={(_date) => {
                setSearchParams({
                  ...Object.fromEntries(searchParams.entries()),
                  end: _date.format("YYYY-MM"),
                });
              }}
              value={parseEndDateQueryParam(endQueryParam)}
              style={{
                width: "109px",
              }}
            />
          )}

          <Dropdown
            menu={{
              items: Object.keys(AttendanceStatusTitles[userType] || {}).map(
                (_status) => {
                  return {
                    label:
                      AttendanceStatusTitles[userType][
                        _status as AttendanceStatus
                      ] || _status,
                    key: _status,
                  };
                }
              ),
              selectable: true,
              selectedKeys: selectedStatuses,
              multiple: true,
              onSelect: ({ selectedKeys }) => {
                setSearchParams({
                  ...Object.fromEntries(searchParams.entries()),
                  status: selectedKeys.join(","),
                });
              },
              onDeselect: ({ selectedKeys }) => {
                setSearchParams({
                  ...Object.fromEntries(searchParams.entries()),
                  status: selectedKeys.join(","),
                });
              },
            }}
            trigger={["click"]}
          >
            <Button icon={<Filter />}>
              Filter by Status{" "}
              <Badge
                color="blue"
                count={selectedStatuses.length}
                className="ml-2"
              />
            </Button>
          </Dropdown>

          <Input
            allowClear
            placeholder="Search by name or ID"
            prefix={<Search />}
            onChange={(e) => {
              setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                search: e.target.value,
              });
            }}
            value={searchQuery}
          />
        </Space>
      }
      styles={{
        body: {
          padding: "0px",
        },
      }}
    >
      <ConfigProvider
        renderEmpty={() => (
          <div style={{ textAlign: "center" }}>
            <Empty
              className="my-12"
              description={
                _hasRecordsOutsideOfFilteredState
                  ? "There is data outside of your current filters"
                  : "No data"
              }
            >
              {_hasRecordsOutsideOfFilteredState && (
                <Button
                  className="m-auto"
                  danger
                  onClick={() => {
                    setSearchParams({
                      ...Object.fromEntries(searchParams.entries()),
                      status: Object.keys(
                        AttendanceStatusTitles[userType] || {}
                      ).join(","),
                      search: "",
                    });
                  }}
                >
                  Clear filters
                </Button>
              )}
            </Empty>
          </div>
        )}
      >
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={{
            hideOnSinglePage: true,
            style: { marginRight: "24px" },
          }}
          rowKey="id"
          scroll={{ x: "max-content" }}
        />
      </ConfigProvider>
    </Card>
  );
}
