// Antd
import { Button, Form, InputNumber, message, Upload } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { CloudUploadOutlined, DeleteOutlined } from "@ant-design/icons";

const imageMimeType = /^image\/(jpeg|png)$/;
const pdfMimeType = "application/pdf";

export default function EasScheduleFormBillingStatement({
  fileToUpload,
  setFileToUpload,
}: {
  fileToUpload: File | null;
  setFileToUpload: (file: File | null) => void;
}) {
  return (
    <>
      <Form.Item
        name="amountBilled"
        label={<span className="text-black font-bold">Amount billed</span>}
        rules={[
          {
            required: true,
            message: "Please enter a number",
          },
          {
            type: "number",
            message: "Please enter a number",
          },
        ]}
      >
        <InputNumber
          size="large"
          prefix="$"
          className="w-full"
          controls={false}
        />
      </Form.Item>

      <p className="mt-8 text-lg font-semibold tracking-normal">
        Billing statement attachment:
      </p>

      <Dragger
        {...{
          name: "file",
          multiple: false,
          customRequest: ({ file, onSuccess }) => {
            setFileToUpload(file as File);

            // Update antd upload component
            onSuccess?.({});
          },
          beforeUpload: (file) => {
            const isImage = imageMimeType.test(file.type);
            const isPdf = file.type === pdfMimeType;

            if (!isImage && !isPdf) {
              message.error("You can only upload PDF, JPG or PNG files");
              return Upload.LIST_IGNORE; // Return false to prevent the upload
            }

            return true; // Allow other file types to be uploaded
          },
          showUploadList: false,
        }}
        // disabled={!!fileToUpload}
      >
        <p className="ant-upload-drag-icon">
          <CloudUploadOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">.pdf, .jpg or .png - 10MB max</p>
      </Dragger>

      {fileToUpload && (
        <p className="mt-4 pb-2 flex items-center border-b border-solid border-gray-200">
          <span className="text-gray-500">{fileToUpload.name}</span>

          <span className="flex-grow"></span>

          <span className="text-gray-500">
            <Button
              size="small"
              type="text"
              onClick={() => {
                setFileToUpload(null);
              }}
            >
              <DeleteOutlined className="w-4 h-4" />
            </Button>
          </span>
        </p>
      )}
    </>
  );
}
