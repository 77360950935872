import { UserType } from "@/hooks/useUrlParams";
import {
  AttendanceStatusColors,
  AttendanceStatusTitles,
  IAttendance,
} from "@/types/attendance-sheet.types";

/*
 * Get the status for the attendance
 */
export function getDayStatus(
  attendance: IAttendance | null,
  { userType }: { userType: UserType }
) {
  if (!attendance) return { title: "", color: "" };

  let result = { title: "", color: "" };

  if (attendance.status) {
    result = {
      title: AttendanceStatusTitles[userType][attendance.status],
      color: AttendanceStatusColors[userType][attendance.status],
    };
  }
  return result;
}
