import { useEffect, useState } from "react";

// Internal
import { DayStatusColor } from "@/types/attendance-sheet.types";
import { getDateRangeString, parseDayMonthYearString } from "@/utils/dates";
import EasScheduleLegend from "./EasScheduleLegend";
import EasScheduleFormBillingStatement from "./EasScheduleFormBillingStatement";

// Antd
import { Calendar, Card, Divider, Form, Radio, Space, Tooltip } from "antd";

// MISC
import dayjs from "dayjs";

export default function EasScheduleForm({
  attendaceSheet,
  daysNotInCare,
  setDaysNotInCare,
  terminationDate,
  fileToUpload,
  setFileToUpload,
  showProviderResubmissionUI,
}: any) {
  // Search params
  const startDate = attendaceSheet?.periodStartDate;
  const endDate = attendaceSheet?.periodEndDate;

  const [charged, setCharged] = useState(true);

  const form = Form.useFormInstance();
  /*
   * If in re-submission mode, then we need to
   * show the originally filled out attendance sheet
   */
  useEffect(() => {
    if (attendaceSheet) {
      if (showProviderResubmissionUI) {
        // Set `Was the family charged for the entire month?` radio button
        setCharged(
          attendaceSheet.amountBilled === null &&
            attendaceSheet.amountBilled === undefined
        );

        // Set amount billed input value
        form.setFieldsValue({
          amountBilled: attendaceSheet.amountBilled,
        });
      } else {
        // Loop through the `days` array, and file any day not included in that array as `daysNotInCare`
        const _daysNotInCare = {};
        const _days = attendaceSheet.days.map((day: any) => {
          return day.dateAttended;
        });

        const _startDate = dayjs(startDate);
        const _endDate = dayjs(endDate);

        for (
          let date = _startDate;
          date.isBefore(_endDate);
          date = date.add(1, "day")
        ) {
          if (!_days.includes(date.format("YYYY-MM-DD"))) {
            _daysNotInCare[date.date()] = true;
          }
        }

        setDaysNotInCare(_daysNotInCare);
      }
    }
  }, [
    attendaceSheet,
    endDate,
    form,
    setDaysNotInCare,
    showProviderResubmissionUI,
    startDate,
  ]);

  return (
    <div className="relative">
      <Divider className="my-12" />

      <div className="flex flex-col xl:flex-row gap-0 xl:gap-12 mt-12">
        <div className="w-full xl:w-1/2">
          <Card title="1. Attendance">
            <p className="font-bold">
              Please select dates that child was NOT in care for:
            </p>

            <Calendar
              className="mt-4 border border-solid border-gray-200 rounded-lg select-none"
              fullscreen={false}
              mode="month"
              headerRender={() => (
                <div className="py-3 pl-4 font-semibold">
                  {getDateRangeString(startDate, endDate)}
                </div>
              )}
              disabledDate={(date) => {
                // If date is a weekend, then we should disable it
                if (date.day() === 0 || date.day() === 6) {
                  return true;
                }

                return false;
              }}
              validRange={[
                parseDayMonthYearString(startDate),
                parseDayMonthYearString(endDate),
              ]}
              value={parseDayMonthYearString(startDate)}
              fullCellRender={(date) => {
                const _isDisabled = date.day() === 0 || date.day() === 6;

                const statusBgColor =
                  daysNotInCare?.[date.date()] === true
                    ? DayStatusColor.NO_CARE_PROVIDED
                    : DayStatusColor.ATTENDED;

                const _dateCollidesWithTerminationDate =
                  terminationDate && date.isAfter(dayjs(terminationDate));

                // if date is outside of validRange, then we should disable it
                if (
                  date.isBefore(dayjs(startDate)) ||
                  date.isAfter(dayjs(endDate))
                ) {
                  return date.date();
                }

                // Termination date
                if (terminationDate && date.isSame(terminationDate)) {
                  return (
                    <div
                      className="rounded-md bg-red-500 text-white"
                      style={{
                        display: "inline-block",
                        minWidth: "24px",
                        height: "24px",
                        lineHeight: "25px",
                        cursor: "default",
                      }}
                    >
                      <div className="ant-picker-calendar-date-value">
                        {date.date()}
                      </div>

                      <div className="ant-picker-calendar-date-content"></div>
                    </div>
                  );
                }

                // If after the termination date
                if (terminationDate && date.isAfter(dayjs(terminationDate))) {
                  return (
                    <Tooltip title="Cannot select date after termination.">
                      <div className="cursor-default opacity-50">
                        {date.date()}
                      </div>
                    </Tooltip>
                  );
                }

                return (
                  <Tooltip
                    title={
                      _dateCollidesWithTerminationDate
                        ? "Unable to edit since it collides with termination date"
                        : undefined
                    }
                  >
                    <div
                      className={`rounded-md ${
                        !_isDisabled
                          ? `${statusBgColor} text-white`
                          : "text-black opacity-20"
                      }`}
                      onClick={
                        _dateCollidesWithTerminationDate
                          ? undefined
                          : () => {
                              setDaysNotInCare({
                                ...daysNotInCare,
                                [date.date()]:
                                  daysNotInCare?.[date.date()] !== true
                                    ? true
                                    : false,
                              });
                            }
                      }
                      style={{
                        display: "inline-block",
                        minWidth: "24px",
                        height: "24px",
                        lineHeight: "25px",
                        cursor: _dateCollidesWithTerminationDate
                          ? "not-allowed"
                          : "pointer",
                      }}
                    >
                      <div className="ant-picker-calendar-date-value">
                        {date.date()}
                      </div>

                      <div className="ant-picker-calendar-date-content"></div>
                    </div>
                  </Tooltip>
                );
              }}
              style={{ maxWidth: "500px" }}
            />

            <EasScheduleLegend />
          </Card>
        </div>

        <div className="w-full xl:w-1/2 pt-12 xl:py-0">
          <Card title="2. Billing Statement">
            <div>
              <Form.Item
                label={
                  <span className="text-black font-bold">
                    Was the family charged for the entire month?
                  </span>
                }
                name="chargedForEntireMonth"
                rules={[
                  {
                    required: true,
                    message: "This is required",
                  },
                ]}
              >
                <Radio.Group
                  value={charged}
                  onChange={(e) => {
                    setCharged(e.target.value);
                  }}
                >
                  <Space direction="horizontal">
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </div>

            {charged !== true && (
              <div>
                <EasScheduleFormBillingStatement
                  fileToUpload={fileToUpload}
                  setFileToUpload={setFileToUpload}
                />
              </div>
            )}
          </Card>
        </div>
      </div>

      <Divider className="my-12" />
    </div>
  );
}
