// React
import { useMemo } from "react";

export enum UserType {
  PROVIDER = "provider",
  FAMILY = "family",
}

export function useUrlParams({
  searchParams,
}: {
  searchParams: URLSearchParams;
}) {
  // decode data from base64
  const dataQueryParam = searchParams.get("data");

  const params = useMemo(() => {
    if (dataQueryParam) {
      try {
        return JSON.parse(atob(dataQueryParam));
      } catch (e) {
        console.error("Error parsing base64", e);
      }
    }
    return {};
  }, [dataQueryParam]);

  return {
    params,
    providerId: params.providerId,
    familyId: params.familyId,
    startDate: params.startDate,
    endDate: params.endDate,
    // The kind of view we are in
    isProviderView: !!params.providerId,
    isFamilyView: !!params.familyId,
    userType: params.providerId ? UserType.PROVIDER : UserType.FAMILY,
  };
}
