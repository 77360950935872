import { useSearchParams } from "react-router-dom";

// Internal
import { useUrlParams } from "@/hooks/useUrlParams";
import { IAttendance } from "@/types/attendance-sheet.types";

// Antd
import { Checkbox, Form, Input, Radio, Space } from "antd";

// MISC
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";

export default function EasAcknowledgements({
  attendanceSheet,
  showProviderResubmissionUI,
}: {
  attendanceSheet: IAttendance;
  showProviderResubmissionUI: boolean;
}) {
  const form = Form.useFormInstance();
  const parentInAgreementWithProviderDetails = Form.useWatch(
    "parentInAgreementWithProviderDetails",
    form
  );

  // Search params
  const [searchParams] = useSearchParams();
  const { isFamilyView, isProviderView } = useUrlParams({
    searchParams,
  });

  const _disableProviderSide = isFamilyView;
  const _disableFamilySide = isProviderView;

  return (
    <>
      <div className="flex flex-col md:flex-row gap-12 justify-center max-w-[1035px] mt-8 mx-auto">
        {!_disableProviderSide && (
          <div>
            <h3 className="text-lg font-bold">Provider</h3>

            <Form.Item
              name="providerName"
              label={
                <span className="text-gray-500">
                  Please type your full name here
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
                {
                  validator: (_, value: string) => {
                    if (value && value.length > 50) {
                      return Promise.reject(
                        new Error("Cannot exceed 50 characters")
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="providerCheckbox"
              valuePropName="checked"
              rules={[
                {
                  validator: async (_, value) => {
                    if (!value) {
                      return Promise.reject("This is required");
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Checkbox>
                By clicking this box I acknowledge that this attendance
                submission is correct and true.
              </Checkbox>
            </Form.Item>

            {!showProviderResubmissionUI &&
              attendanceSheet.submittedByProviderDate && (
                <p className="font-bold">
                  Attested on{" "}
                  {dayjs(attendanceSheet.submittedByProviderDate).format(
                    "MMM DD, YYYY"
                  )}
                  .
                </p>
              )}
          </div>
        )}

        {!_disableFamilySide && (
          <div>
            <Form.Item
              name="parentName"
              label={
                <span className="text-black font-bold">
                  Please type your full name here
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
                {
                  validator: (_, value: string) => {
                    if (value && value.length > 50) {
                      return Promise.reject(
                        new Error("Cannot exceed 50 characters")
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="parentInAgreementWithProviderDetails"
              label={
                <span className="text-black font-bold">
                  Are you in agreement with the Provider’s report?
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
              ]}
              className="block mt-4"
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={true}>
                    Yes, I am in agreement with the provider's attendance
                    submission for this child.
                  </Radio>
                  <Radio value={false}>
                    No - By choosing this option, I am stating that I am not in
                    agreement with the provider's attendance submission for this
                    child. I understand that this attendance sheet will be sent
                    back to the provider to correct. Once the correction is
                    made, the provider will then resubmit the attendance sheet
                    for my review and approval.
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>

            {parentInAgreementWithProviderDetails === false && (
              <Form.Item
                name="declineReason"
                label={
                  <span className="text-black font-bold">
                    Reason for disagreement
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "This is required",
                  },
                ]}
                className="block mt-4"
              >
                <TextArea rows={5} />
              </Form.Item>
            )}

            {attendanceSheet.submittedByFamilyDate && (
              <p className="font-bold">
                Attested on{" "}
                {dayjs(attendanceSheet.submittedByFamilyDate).format(
                  "MMM DD, YYYY"
                )}
                .
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
}
