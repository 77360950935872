export const loadNewRelicSessionReplayScript = () => {
  if (import.meta.env.MODE === "production") {
    const siteEnv = import.meta.env.VITE_SITE_ENV;

    if (siteEnv) {
      const script = document.createElement("script");
      script.type = "text/javascript";

      if (siteEnv === "dev") {
        script.src = "/scripts/newrelic-dev.js";
      } else if (siteEnv === "staging") {
        script.src = "/scripts/newrelic-staging.js";
      } else if (siteEnv === "prod") {
        script.src = "/scripts/newrelic-prod.js";
      }

      document.body.appendChild(script);
    }
  }
};
