import React, { ReactNode } from "react";
import { Button, Drawer } from "antd";
import { CloseOutlined } from "@ant-design/icons";

export const DetailDrawerSectionTitle = ({
  className,
  title,
}: {
  className: string;
  title: ReactNode;
}) => (
  <p
    className={`block text-sm font-bold text-gray-500 opacity-80 uppercase ${className}`}
  >
    {title}
  </p>
);
export const DetailDrawerLineItem = ({
  title,
  value,
  className
}: {
  title: ReactNode;
  value: ReactNode;
  className?: string
}) => (
  <div className={`flex items-center ${className ? className : ''}`}>
    <div className="flex-grow font-medium">{title}</div>

    <div className="text-gray-700 text-right max-w-[50%]">{value}</div>
  </div>
);

export default function DetailsDrawer({
  title,
  description,
  items,
  open,
  setOpen,
}: {
  title: ReactNode;
  description?: ReactNode;
  items: any[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <Drawer
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      headerStyle={{ display: "none" }}
    >
      <div className="flex items-center m-[-24px] pt-[24px] px-[24px] pb-6 border-b mb-4">
        <div className="flex-grow">
          <h2 className="font-bold text-[15px]">{title}</h2>
          <h5 className="text-gray-600">{description}</h5>
        </div>

        <div>
          <Button
            size="small"
            icon={<CloseOutlined className="w-[0.85rem] h-[0.85rem]" />}
            onClick={() => {
              setOpen(false);
            }}
            style={{ width: "25px", height: "25px" }}
          />
        </div>
      </div>

      {items?.map((item, index) => (
        <div key={index}>
          <DetailDrawerSectionTitle title={item.title} className="mt-6 mb-4" />

          <div className="flex flex-col gap-2">
            {item.items?.map((item, index) => (
              <DetailDrawerLineItem
                key={index}
                title={item.label}
                value={item.value}
              />
            ))}
          </div>
        </div>
      ))}
    </Drawer>
  );
}
