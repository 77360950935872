import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

type RequestHandler = {
  get: <T>(url: string, config?: AxiosRequestConfig) => Promise<T>;
  post: <T>(
    url: string,
    data: unknown,
    config?: AxiosRequestConfig
  ) => Promise<T>;
};

export const httpRequests: RequestHandler = {
    get: async <T>(url: string, config?: AxiosRequestConfig) => {
        const response: AxiosResponse<T> = await axios.get(url, config);

        return response.data;
    },
    post: async <T>(url: string, data: unknown, config?: AxiosRequestConfig) => {
        const response: AxiosResponse<T> = await axios.post(url, data, config);

        return response.data;
    },
};
