import { useSearchParams } from "react-router-dom";

// Internal
import { ChildCertificate } from "@/api/__types/attendance";
import { useGetChildCertificates } from "@/api/attendance";
import { DATE_FORMAT_US } from "@/utils/dates";

// Antd
import { Alert, Card, Spin, Table, Tooltip } from "antd";
import dayjs from "dayjs";

// Icons
import { InformationFilled } from "@carbon/icons-react";

export interface ChildActiveCertificatesProps {
  childId: string;
  platform: string;
  providerId: string;
}

export default function ChildActiveCertificates({
  childId,
  platform,
  providerId,
}: ChildActiveCertificatesProps) {
  // Search params
  const [searchParams] = useSearchParams();
  const endQueryParam = searchParams.get("end") || "";

  const { isLoading: childCertificatesLoading, data: childCertificates } =
    useGetChildCertificates({
      providerId: providerId || "",
      childId: childId || "",
      platform: platform || "",
      startDate:
        dayjs(endQueryParam).startOf("month").format("YYYY-MM-DD") || "",
    });

  const columns = [
    {
      title: "Certificate ID",
      dataIndex: "certificateId",
      key: "certificateId",
    },
    {
      title: "Service Type",
      dataIndex: "serviceType",
      key: "serviceType",
    },
    {
      title: "Approved Fee Assistance Amount",
      dataIndex: "feeAssistanceRate",
      key: "feeAssistanceRate",
      render: (text: string) => {
        return `$${parseFloat(text).toLocaleString()} / month`;
      },
    },
    {
      title: "Start Date",
      dataIndex: "dateCertBegins",
      key: "dateCertBegins",
      render: (text: string) => {
        return dayjs(text).format(DATE_FORMAT_US);
      },
    },
    {
      title: "End Date",
      dataIndex: "dateCertEnds",
      key: "dateCertEnds",
      render: (text: string, record: ChildCertificate) => {
        if (record.expiringSoon) {
          return (
            <div className="flex items-center gap-2">
              {dayjs(text).format(DATE_FORMAT_US)}{" "}
              <Tooltip
                title={
                  <>
                    <p>
                      Families nearing certificate expiration can reference the
                      “Notes Box” of their certificate approval to confirm any
                      supporting documentation required to maintain fee
                      assistance beyond the next 45 days.
                    </p>

                    <p className="mt-4">
                      If you are unable to determine what is needed or need
                      additional information regarding your account, please
                      contact us at 1-800-424-2246 for further assistance.
                    </p>
                  </>
                }
                placement="left"
                overlayStyle={{ maxWidth: "500px", width: "75vw" }}
              >
                <InformationFilled />
              </Tooltip>
            </div>
          );
        }

        return dayjs(text).format(DATE_FORMAT_US);
      },
    },
  ];

  return (
    <Spin spinning={childCertificatesLoading}>
      <Card
        title="Active Certificates"
        className="mt-10 mb-10"
        styles={{
          body: {
            padding: "0px",
          },
        }}
      >
        <Table
          columns={columns}
          dataSource={childCertificates}
          pagination={{
            hideOnSinglePage: true,
            style: { marginRight: "24px" },
          }}
          rowKey="id"
          rowClassName={(record: ChildCertificate) => {
            return record.expiringSoon ? "status-warn" : "";
          }}
          scroll={{ x: "max-content" }}
        />

        <div className="m-4">
          <Alert
            message={<p className="font-semibold">Legend</p>}
            description={
              <ul className="list-disc ml-6">
                <li>
                  A certificate will only appear in RED, along with the{" "}
                  <InformationFilled className="mt-[-2px] align-middle" /> icon,
                  if it ends within 45 days of today's date with no additional
                  Active approvals.
                </li>

                <li>
                  Hover over the{" "}
                  <InformationFilled className="mt-[-2px] align-middle" /> icon
                  to determine what information the child needs to remain
                  active.
                </li>

                <li>
                  If there are any changes to the approval, such as schedule
                  changes, rate changes, discounts, etc., those are to be
                  reported to Child Care Aware® of America prior to submitting
                  the attendance sheet for the month the change takes effect.
                </li>
              </ul>
            }
          />
        </div>
      </Card>
    </Spin>
  );
}
